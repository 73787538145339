import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
  Hi.
    </Text>
    <p>{`I'm Surya Rajendhran and I love the internet. Welcome to my personal site, I share a few of my thoughts about tech, TV and my love for Modern Family. Currently reading `}<a parentName="p" {...{
        "href": "https://www.minimalistentrepreneur.com"
      }}>{`Minimalist Entrepreneur`}</a>{` by Sahil Lavinginia.`}</p>
    <p><b>Fun fact:</b> {`${new Date().toLocaleString('default', {
        month: 'long'
      })}`} is {`${(new Date().getDate() / new Date(new Date().getFullYear(), new Date().getMonth(), 0).getDate() * 100).toFixed(2)}%`} done</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      